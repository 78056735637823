import React, { useEffect, useState } from "react";
import { Text } from "@ilc-technology/luik";
import Enrich from "../../../Common/services/TextEnricher";
import { ErrorCode, ErrorDetails, StringIndexableDictionary } from "../../../Common/Types";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import parse from "html-react-parser";
import { AppConfig } from "../../../AppConfig";
import { FetchTermsAndConditions, TermsAndCondition } from "../../../Common/services/CentralApiService";
import { convertToErrorDetails, logError } from "../../../Common/services/ErrorService";
import ErrorComponentWrapper from "../../ErrorHandling/ErrorComponentWrapper";
import { getAllMarketInfo } from "@ilc-technology/env-utils";

export interface TermsAndConditionsProps {
  activeLanguage: string;
  program: string;
  market: string;
  onConditionsAcceptanceChange?: (acceptedConditions: SalesCondition, termsVersion?: string) => void;
}

export enum SalesCondition {
  TermsAccepted = 1 << 0,
  PrivacyPolicyAccepted = 1 << 1,
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
  activeLanguage,
  program,
  market,
  onConditionsAcceptanceChange,
}) => {
  const { session } = useSessionContext();
  const { labels } = useDatasources();
  const { user } = session;
  const [termsAndConditions, setTermsAndConditions] = useState<
    StringIndexableDictionary<TermsAndCondition> | undefined
  >(undefined);
  const [termsAndConditionsError, setTermsAndConditionsError] = useState<ErrorDetails | undefined>(undefined);
  const [acceptedConditions, setAcceptedConditions] = useState(0);

  const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");

  const terms = termsAndConditions && termsAndConditions[program ?? ""];
  const fallbackMarket = "UKA";

  // Function to toggle the condition state based on the provided condition (bit)
  const handleConditionChange = (condition: SalesCondition, termsVersion?: string) => {
    if ((acceptedConditions & condition) > 0) {
      return;
    }
    const updatedConditions = acceptedConditions ^ condition; // Toggle the bit
    setAcceptedConditions(updatedConditions);

    if (onConditionsAcceptanceChange) {
      onConditionsAcceptanceChange(condition, termsVersion); // Pass the updated condition
    }
  };

  useEffect(() => {
    if (!activeLanguage) {
      return;
    }
    FetchTermsAndConditions(activeLanguage)
      .then((data) => {
        const programQueried = program == "ILC" ? "ILSY" : program;
        if (data[program] === undefined) {
          const error: ErrorDetails = {
            code: ErrorCode.TermsForChosenProgramNotFound,
            details: {
              additionalDetails: `Could not find terms and conditions for program ${programQueried}`,
            },
          };
          setTermsAndConditionsError(error);
          logError(error);
        } else if (!data[program].version) {
          const error: ErrorDetails = {
            code: ErrorCode.TermsVersionForChosenProgramNotFound,
            details: {
              additionalDetails: `Could not find terms and conditions version for program ${programQueried}`,
            },
          };
          setTermsAndConditionsError(error);
          logError(error);
        } else {
          setTermsAndConditions(data);
        }
      })
      .catch((error) => {
        const errorDetails = convertToErrorDetails(error, ErrorCode.DownloadingTermsFailed);
        logError(errorDetails);
      });
  }, [activeLanguage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const link = getPrivacyPolicyLink();
    setPrivacyPolicyLink(link);
  }, []);

  const getPrivacyPolicyLink = () => {
    const marketsInfo = getAllMarketInfo();

    let marketInfo = marketsInfo.find(
      (m) =>
        m.junoMarketCode.toLowerCase() === market.toLowerCase() &&
        m.languageCode.toLowerCase() === user.languageCode.toLowerCase()
    );

    if (marketInfo == null) {
      marketInfo = marketsInfo.find((m) => m.junoMarketCode.toLowerCase() === market.toLowerCase());
    }

    if (marketInfo == null) {
      marketInfo = marketsInfo.find((m) => m.junoMarketCode.toLowerCase() === fallbackMarket.toLowerCase());

      const errorDetails = {
        code: ErrorCode.PrivacyPolicyForMarketNotFound,
        details: {
          additionalDetails: `Privacy policy not found for market ${market}`,
        },
      };
      logError(errorDetails);
    }

    const domain = AppConfig.environment === "PROD" ? marketInfo?.liveDomain : marketInfo?.qaDomain;

    return `${domain}/legal`;
  };

  return (
    <>
      <div
        className="a-gap-sm flex w-full flex-row items-center self-stretch bg-white"
        onClick={() => handleConditionChange(SalesCondition.TermsAccepted, terms?.version)}
      >
        <ErrorComponentWrapper
          isLoading={!termsAndConditions && !termsAndConditionsError}
          error={termsAndConditionsError}
        >
          {termsAndConditions && terms && (
            <>
              <input
                type="checkbox"
                disabled={(acceptedConditions & SalesCondition.TermsAccepted) > 0}
                aria-label="terms-checkbox"
                data-testid="terms-checkbox"
                onChange={() => {}}
                checked={(acceptedConditions & SalesCondition.TermsAccepted) > 0}
              />
              <Text variant="label-md">
                {parse(
                  Enrich(
                    labels[LabelKey.agreeToTermsAndConditions]?.replace(
                      /(\{termsAndConditions\})/,
                      `<a className="text-primary-accessible underline hover:underline hover:decoration-2" href="${terms.filePath}" target="_blank">$1</a>`
                    ),
                    { label: labels[LabelKey.termsAndConditions] }
                  )
                )}
              </Text>
            </>
          )}
        </ErrorComponentWrapper>
      </div>
      <div
        className="a-gap-sm flex w-full flex-row items-center self-stretch bg-white"
        onClick={() => handleConditionChange(SalesCondition.PrivacyPolicyAccepted, terms?.version)}
      >
        <>
          <input
            type="checkbox"
            disabled={(acceptedConditions & SalesCondition.PrivacyPolicyAccepted) > 0}
            aria-label="privacy-checkbox"
            data-testid="privacy-checkbox"
            onChange={() => {}}
            checked={(acceptedConditions & SalesCondition.PrivacyPolicyAccepted) > 0}
          />
          <Text variant="label-md">
            {parse(
              Enrich(
                labels[LabelKey.agreeToPrivacyPolicy]?.replace(
                  /(\{privacyPolicy\})/,
                  `<a class="text-primary-accessible underline hover:underline hover:decoration-2" href="//${privacyPolicyLink}" target="_blank">$1</a>`
                ),
                { label: labels[LabelKey.privacyPolicy] }
              )
            )}
          </Text>
        </>
      </div>
    </>
  );
};

export default TermsAndConditions;
