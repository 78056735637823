import React, { useState } from "react";
import { Button, Item, Loading, Select, Text, TextInput } from "@ilc-technology/luik";
import { IconButton } from "@ilc-technology/luik";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { User, useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import { convertToAccountUpdateRequest } from "../../../Common/Helpers/AccountHelper";
import InformationBox from "../../InformationBox/InformationBox";
import { CustomTrackingEvent, trackEvent } from "../../../Common/services/Analytics";
import { getErrorDetailsToDisplay, logError } from "../../../Common/services/ErrorService";
import { ErrorDetails } from "../../../Common/Types";
import { TRUE } from "../../../Common/Constants";
import { getCountryName } from "../../../Common/services/Countries";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormikHelpers } from "formik/dist/types";

export type BasicStudentDetails = {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  country: string;
  mobilePhone: string;
};

interface StudentDetailsProps {
  onStudentDetailsChange?: (newStudentDetails: BasicStudentDetails) => void;
}

const StudentDetails: React.FC<StudentDetailsProps> = ({ onStudentDetailsChange }) => {
  const { labels, countries, featureSettings } = useDatasources();
  const { session, language, updateAccount } = useSessionContext();
  const { user } = session;

  const [isFormLoading, setIsFormLoading] = useState(false);
  const [accountUpdateError, setAccountUpdateError] = useState<ErrorDetails | undefined>(undefined);
  const [initialValues, setInitialValues] = useState<BasicStudentDetails>({
    firstName: user.firstName,
    middleName: user.middleName,
    lastName: user.lastName,
    email: user.email,
    country: user.country,
    mobilePhone: user.mobilePhone,
  });
  const [isEditing, setIsEditing] = useState(!user.country);

  const getUsersFullDisplayName = (firstName: string, middleName: string, lastName: string) => {
    const nameParts = [firstName, featureSettings.isMiddleNameEnabled === TRUE ? middleName : "", lastName].filter(
      Boolean
    );
    return nameParts.join(" ");
  };

  const handleSubmit = async (values: BasicStudentDetails, _: FormikHelpers<BasicStudentDetails>) => {
    trackEvent(session.opportunity.id, CustomTrackingEvent.SaveCompleteStudentDetailsPayment);
    setIsFormLoading(true);
    setAccountUpdateError(undefined);

    const request = convertToAccountUpdateRequest(values as User);
    const result = await updateAccount(request);

    setIsFormLoading(false);
    setIsEditing(!result.isSuccessful);
    if (!result.isSuccessful) {
      logError(result.error);
      setIsEditing(true);
      setAccountUpdateError(result.error);
    } else {
      setInitialValues(values);
      onStudentDetailsChange && onStudentDetailsChange(values);
    }
  };

  const getValidationSchema = (): any => {
    return Yup.object({
      firstName: Yup.string().required(),
      middleName: Yup.string().notRequired(),
      lastName: Yup.string().required(),
      email: Yup.string().notRequired(),
      country: Yup.string().required(),
      mobilePhone: Yup.string().notRequired(),
    });
  };

  return (
    <div className="a-gap flex flex-col">
      <div className="absolute right-0 top-0">
        <IconButton
          data-testid="edit-student-details"
          iconName={isEditing ? "close" : "crayon"}
          intent={isEditing ? "secondary-black" : "primary-black"}
          size="xs"
          aria-label="edit-student-details"
          onPress={() => {
            setIsEditing(!isEditing);
          }}
        />
      </div>
      <Text variant="paragraph-body">{labels[LabelKey.studentDetailsDescription]}</Text>

      {!initialValues.country && !isEditing && (
        <div
          onClick={() => {
            setIsEditing(!isEditing);
          }}
        >
          <InformationBox
            intent="warning"
            title={labels[LabelKey.missingDetails]}
            content={labels[LabelKey.missingCountry]}
            iconName={"alert-circle-outlined"}
          />
        </div>
      )}
      {!isEditing && countries && (
        <div className="flex flex-col">
          <Text data-testid="person" variant="paragraph-body">
            {getUsersFullDisplayName(initialValues.firstName, initialValues.middleName, initialValues.lastName)}
          </Text>
          <Text data-testid="person-mobilePhone" variant="paragraph-body">
            {initialValues.mobilePhone}
          </Text>
          <Text data-testid="person-email" variant="paragraph-body">
            {initialValues.email}
          </Text>
          {initialValues.country && (
            <Text data-testid="person-country" variant="paragraph-body">
              {getCountryName(initialValues.country, language, countries[initialValues.country])}
            </Text>
          )}
        </div>
      )}
      {isEditing && (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema()}
            validateOnChange={true}
            validateOnMount={true}
            onSubmit={handleSubmit}
          >
            {({
              setFieldValue,
              isSubmitting,
              handleSubmit,
              dirty,
              values,
              handleBlur,
              handleChange,
              touched,
              errors,
              isValid,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="a-gap-sm flex flex-col">
                  <TextInput
                    data-testid="firstName"
                    aria-label="firstName"
                    isRequired={true}
                    name="firstName"
                    label={labels[LabelKey.firstName]}
                    placeholder={labels[LabelKey.firstName]}
                    type="text"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isDisabled={isFormLoading || isSubmitting}
                    isInvalid={errors.firstName != null}
                    validationState={errors.firstName ? "invalid" : "valid"}
                    errorMessage={labels[LabelKey.validationMessage]}
                    touched={touched.firstName}
                  />
                  {featureSettings.isMiddleNameEnabled === TRUE && (
                    <TextInput
                      aria-label="middleName"
                      data-testid="middleName"
                      name="middleName"
                      label={labels[LabelKey.middleName]}
                      placeholder={labels[LabelKey.middleName]}
                      type="text"
                      value={values.middleName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isDisabled={isFormLoading}
                      isInvalid={errors.middleName != null}
                      errorMessage={labels[LabelKey.validationMessage]}
                      touched={touched.middleName}
                    />
                  )}
                  <TextInput
                    data-testid="lastName"
                    aria-label="lastName"
                    isRequired={true}
                    name="lastName"
                    label={labels[LabelKey.lastName]}
                    placeholder={labels[LabelKey.lastName]}
                    type="text"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isDisabled={isFormLoading || isSubmitting}
                    isInvalid={errors.lastName != null}
                    validationState={errors.lastName ? "invalid" : "valid"}
                    errorMessage={labels[LabelKey.validationMessage]}
                    touched={touched.lastName}
                  />
                  <div data-testid="country-select">
                    <Select
                      aria-label="country-select"
                      name="country-select"
                      label={labels[LabelKey.country]}
                      className="mb-4 mt-0"
                      defaultSelectedKey={values.country}
                      trackingInfo="track-select"
                      isRequired={true}
                      onSelectionChange={async (value) => await setFieldValue("country", value.toString())}
                      disabled={isFormLoading}
                      onBlur={handleBlur}
                      validationState={errors.country ? "invalid" : "valid"}
                      errorMessage={labels[LabelKey.validationMessage]}
                      touched={touched.country}
                    >
                      {Object.entries(countries).map(([countryCode, countryName]) => (
                        <Item key={countryCode} value={countryCode}>
                          {getCountryName(countryCode, language, countryName)}
                        </Item>
                      ))}
                    </Select>
                  </div>
                </div>
                {accountUpdateError && (
                  <InformationBox
                    intent="alert"
                    title={labels[LabelKey.saveFailed]}
                    content={labels[LabelKey.saveFailedDescription]}
                    iconName={"alert-circle-outlined"}
                    additionalInfo={getErrorDetailsToDisplay(accountUpdateError)}
                    testid="account-update-error"
                  />
                )}
                <div className="float-right mt-8 flex flex-row items-center gap-6">
                  <Button
                    aria-label="cancel-button"
                    data-testid="cancel-button"
                    intent="secondary-black"
                    size="base"
                    onPress={() => {
                      setIsEditing(!isEditing);
                      setAccountUpdateError(undefined);
                    }}
                    isDisabled={isFormLoading || isSubmitting}
                  >
                    {labels[LabelKey.cancel]}
                  </Button>
                  {isFormLoading || isSubmitting ? (
                    <div className="save-details-button save-details-loading">
                      <Loading className="loading save-details-loading-spinner" size="lg" />
                    </div>
                  ) : (
                    <input
                      aria-label="save-details-button"
                      data-testid="save-details-button"
                      className="save-details-button"
                      type="submit"
                      disabled={isFormLoading || isSubmitting || !dirty || !isValid}
                      value={labels[LabelKey.save]}
                    />
                  )}
                </div>
              </form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default StudentDetails;
