import { useFormikContext } from "formik";
import { Item, Select, TextInput, Text } from "@ilc-technology/luik";
import { LabelKey } from "../../Common/StoryblokTypes";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import IntlTelInput from "intl-tel-input/react";
import { useCallback, useState } from "react";
import { nameofFactory } from "../../Common/Helpers/TextHelper";
import { PropsFromChilds } from "./StudentDetailsFullUpgrade";

interface ParentDetailsProps {
  onValueChange: (data: Partial<PropsFromChilds>) => void;
}

interface ContactDetailsForm {
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  contactRole: string;
}

const ParentDetails: React.FC<ParentDetailsProps> = ({ onValueChange }) => {
  const { labels, relationships } = useDatasources();
  const formik = useFormikContext<ContactDetailsForm>();
  const [isPhoneNumberValid, setPhoneNumberValid] = useState(true);
  const nameofContactDetailsForm = nameofFactory<ContactDetailsForm>();

  const touchSelect = useCallback((elementName: string): ((isOpen: boolean) => void) => {
    return (isOpen) => {
      if (!isOpen) {
        formik.setTouched({ ...formik.touched, [elementName]: true });
      }
    };
  }, []);

  const onFormValueChange = (data: Partial<PropsFromChilds>) => {
    if (data.isPhoneNumberValid !== undefined) {
      setPhoneNumberValid(data.isPhoneNumberValid);
      onValueChange(data);
    }
  };

  return (
    <div className="a-gap-sm mt-8 flex flex-col">
      <Text variant="label-lg-bold">{labels[LabelKey.parentDetails]}</Text>
      <div className="a-gap-sm flex flex-col">
        <TextInput
          data-testid="contactFirstName-input"
          isRequired={true}
          validationState={formik.errors.contactFirstName && formik.touched.contactFirstName ? "invalid" : "valid"}
          errorMessage={labels[LabelKey.validationMessage]}
          name={nameofContactDetailsForm.contactFirstName}
          label={labels[LabelKey.firstName]}
          placeholder={labels[LabelKey.firstName]}
          type="text"
          value={formik.values.contactFirstName}
          onChange={formik.handleChange}
          isDisabled={formik.isSubmitting}
          onBlur={formik.handleBlur}
          touched={formik.touched.contactFirstName}
          autoFocus={!formik.values.contactFirstName}
        />
        <TextInput
          data-testid="contactLastName-input"
          isRequired={true}
          validationState={formik.errors.contactLastName && formik.touched.contactLastName ? "invalid" : "valid"}
          errorMessage={labels[LabelKey.validationMessage]}
          name={nameofContactDetailsForm.contactLastName}
          label={labels[LabelKey.lastName]}
          placeholder={labels[LabelKey.lastName]}
          type="text"
          value={formik.values.contactLastName}
          onChange={formik.handleChange}
          isDisabled={formik.isSubmitting}
          onBlur={formik.handleBlur}
          touched={formik.touched.contactLastName}
        />
        <TextInput
          data-testid="contactEmail-input"
          isRequired={true}
          name={nameofContactDetailsForm.contactEmail}
          validationState={formik.errors.contactEmail && formik.touched.contactEmail ? "invalid" : "valid"}
          errorMessage={labels[LabelKey.validationEmailMessage]}
          label={labels[LabelKey.email]}
          placeholder={labels[LabelKey.email]}
          type="email"
          value={formik.values.contactEmail}
          onChange={formik.handleChange}
          isDisabled={formik.isSubmitting}
          onBlur={formik.handleBlur}
          touched={formik.touched.contactEmail}
        />
        <div
          className={`tel-container a-rounded flex flex-col justify-center border border-neutral-300 
                        ${(formik.errors.contactPhone && formik.touched.contactPhone) || (formik.touched.contactPhone && !isPhoneNumberValid) ? "tel-invalid" : ""} 
                        ${formik.isSubmitting ? "tel-container-disabled" : ""}`}
        >
          <IntlTelInput
            data-testid="contactPhone-input"
            initialValue={formik.values.contactPhone}
            onChangeValidity={(value: boolean) => {
              onFormValueChange({ isPhoneNumberValid: value });
            }}
            onChangeNumber={async (value) =>
              await formik.setFieldValue(nameofContactDetailsForm.contactPhone, value, true)
            }
            usePreciseValidation={true}
            initOptions={{
              utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@21.1.1/build/js/utils.js",
              containerClass: "tel",
              nationalMode: false,
              strictMode: true,
              formatAsYouType: true,
              customPlaceholder: function () {
                return labels[LabelKey.phoneNumber];
              },
              initialCountry: "auto",
              geoIpLookup: function (success, failure) {
                fetch("https://ipapi.co/json")
                  .then(function (res) {
                    return res.json();
                  })
                  .then(function (data) {
                    success(data.country_code);
                  })
                  .catch(function () {
                    failure();
                  });
              },
            }}
            inputProps={{
              onBlur: formik.handleBlur(nameofContactDetailsForm.contactPhone),
            }}
          />
        </div>
        <Select
          data-testid="contactRelationship-select"
          name="contactRelationshipCode-select"
          label={labels[LabelKey.parentRelationship]}
          className="mt-0"
          defaultSelectedKey={formik.values.contactRole}
          validationState={formik.errors.contactRole && formik.touched.contactRole ? "invalid" : "valid"}
          errorMessage={labels[LabelKey.validationMessage]}
          trackingInfo="track-select"
          isRequired={true}
          onSelectionChange={async (value) =>
            await formik.setFieldValue(nameofContactDetailsForm.contactRole, value, true)
          }
          disabled={formik.isSubmitting}
          touched={formik.touched.contactRole}
          onBlur={formik.handleBlur}
          onOpenChange={touchSelect(nameofContactDetailsForm.contactRole)}
        >
          {Object.entries(relationships).map(([relationshipCode, relationshipName]) => (
            <Item key={relationshipCode} value={relationshipCode}>
              {labels["relationship_" + relationshipName] ?? relationshipName}
            </Item>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default ParentDetails;
