enum StoryblokVersion {
  published = "published",
  draft = "draft",
}

const version = StoryblokVersion[import.meta.env.VITE_STORYBLOK_VERSION as keyof typeof StoryblokVersion];

if (version !== StoryblokVersion.published && version !== StoryblokVersion.draft) {
  throw new Error(`Invalid VITE_STORYBLOK_VERSION value: ${version}`);
}

export const AppConfig = {
  user: {
    defaultMarket: "WWA",
    defaultLanguage: "EN",
  },
  storyBlok: {
    accessToken: import.meta.env.VITE_STORYBLOK_API_TOKEN!,
    previewAccessToken: import.meta.env.VITE_STORYBLOK_API_PREVIEW_TOKEN!,
    sharedComponentDataSlug: "sharedcomponentdata",
    homePageSlug: "home",
    version: version,
    datasourceEntries: "cdn/datasource_entries",
  },
  api: {
    quotation: import.meta.env.VITE_QUOTATION_API!,
    efPlanner: import.meta.env.VITE_EF_PLANNER_API_URL!,
    productWebsite: import.meta.env.VITE_PRODUCT_WEBSITE_API_URL!,
    atlasWebsite: import.meta.env.VITE_ATLAS_WEBSITE_URL!,
    centralApi: import.meta.env.VITE_CENTRAL_API!,
    customerServiceApi: import.meta.env.VITE_CUSTOMER_SERVICE_API!,
  },
  sessionStorageKeys: {
    authToken: "authToken",
    jwtToken: "access_token",
  },
  oraclePaymentLibrary: {
    source: import.meta.env.VITE_ORACLE_PAYMENT_LIBRARY_SOURCE!,
    integrity: import.meta.env.VITE_ORACLE_PAYMENT_LIBRARY_INTEGRITY!,
  },
  atlas: {
    defaultLanguage: "en",
  },
  efcom: {
    defaultMarket: "we",
    defaultProgramCode: "ils",
  },
  content: {
    defaultLanguage: "en",
    logoImageUrl: "https://a.storyblok.com/f/234558/55x32/b8b646eb2e/logo-black.svg",
    defaultBannerImage: "https://a.storyblok.com/f/234558/1199x606/7e0586f562/hero.png",
    defaultSchoolImage: "https://a.storyblok.com/f/234558/1199x606/7e0586f562/hero.png",
    defaultThankYouImage: "https://a.storyblok.com/f/234558/543x627/6ae1a97fe0/thankyouimage.png",
  },
  cookieConsent: {
    domainScriptId: import.meta.env.VITE_ONETRUST_DOMAIN_SCRIPT_ID!,
    onetrustSource: "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
    autoblockSource: `https://cdn-ukwest.onetrust.com/consent/${import.meta.env
      .VITE_ONETRUST_DOMAIN_SCRIPT_ID!}/OtAutoBlock.js`,
  },
  analytics: {
    googleMeasurementId: import.meta.env.VITE_GOOGLE_MEASUREMENT_ID!,
  },
  rum: {
    APPLICATION_ID: import.meta.env.VITE_RUM_APPLICATION_ID,
    APPLICATION_VERSION: import.meta.env.VITE_RUM_APPLICATION_VERSION,
    APPLICATION_REGION: import.meta.env.VITE_RUM_APPLICATION_REGION,
    IDENTITY_POOL_ID: import.meta.env.VITE_RUM_IDENTITY_POOL_ID,
  },
  environment: import.meta.env.VITE_ENVIRONMENT!,
};
